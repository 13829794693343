import { createContext, useState, useEffect } from "react";
import moment from "moment-timezone";

import { RegistrationDates } from "../../models/registration";

export interface Registration {
  logos: string[];
  price: number;
  items: string[];
  date: string | undefined;
}

export interface IShotgunConfig {
  shotgunMoment: "" | moment.Moment | undefined;
  registrationsOn: boolean | "" | undefined;
  prefix: string;
}

export interface IRegistrationContext {
  registrations: { [key: string]: Registration };
  shotgunConfig: IShotgunConfig;
  shotgunGuestsConfig: IShotgunConfig;
  monthNextEdition: string | undefined;
  timezone: string;
  registrationsClosed: boolean;
  updateRegistration: (dates: Partial<RegistrationDates>) => void;
}

const defaultRegistrationContext: IRegistrationContext = {
  registrations: {
    ncv: {
      logos: ["ncv.png"],
      price: 20,
      items: ["ncv", "t_shirt", "goodies", "meal"],
      date: undefined,
    },
    cvg: {
      logos: ["cvg.png"],
      price: 20,
      items: ["cvg", "t_shirt", "goodies", "meal"],
      date: undefined,
    },
    ncv_cvg: {
      logos: ["ncv.png", "cvg.png"],
      price: 350,
      items: ["both", "t_shirt_both", "goodies", "meals"],
      date: undefined,
    },
  },
  shotgunConfig: {
    shotgunMoment: undefined,
    registrationsOn: false,
    prefix: "",
  },
  shotgunGuestsConfig: {
    shotgunMoment: undefined,
    registrationsOn: undefined,
    prefix: "",
  },
  monthNextEdition: "Nov. 2024",
  timezone: "Europe/Paris",
  registrationsClosed: true,
  updateRegistration: () => {},
};

const params = {
  globalShotgun: "2023-11-21T20:00:00",
  guestShotgun: undefined,
  registrationsClosed: true,
  nextEditionMonth: "2024-11-01",
  ncv: "2024-11-30",
  cvg: "2024-12-01",
  timezone: "Europe/Paris",
};

export const RegistrationContext = createContext<IRegistrationContext>(
  defaultRegistrationContext
);

export const useRegistrationContext = (): IRegistrationContext => {
  const shotgunDateToConfig = (date: string | undefined, prefix: string) => {
    const shotgunMoment = date && moment.tz(date, params.timezone);
    const registrationsOn =
      shotgunMoment && moment.tz(params.timezone).isAfter(shotgunMoment);
    return { shotgunMoment, registrationsOn, prefix };
  };

  const [shotgunConfig, setShotgunConfig] = useState<IShotgunConfig>(
    shotgunDateToConfig(params.globalShotgun, "")
  );
  const [shotgunGuestsConfig, setShotgunGuestsConfig] =
    useState<IShotgunConfig>(
      shotgunDateToConfig(params.guestShotgun, "/guests")
    );
  const [registrationsClosed, setRegistrationsClosed] = useState<boolean>(
    params.registrationsClosed
  );
  const [monthNextEdition, setMonthNextEdition] = useState<string | undefined>(
    params.nextEditionMonth && moment(params.nextEditionMonth).format("MMM. YY")
  );
  const [ncvDate, setNCVDate] = useState<string | undefined>(
    params.ncv && moment(params.ncv).format("YYYY-MM-DD")
  );
  const [cvgDate, setCVGDate] = useState<string | undefined>(
    params.cvg && moment(params.cvg).format("YYYY-MM-DD")
  );

  const updateRegistration = (dates: Partial<RegistrationDates>) => {
    setShotgunConfig(shotgunDateToConfig(params.globalShotgun, ""));
    setShotgunGuestsConfig(shotgunDateToConfig(params.guestShotgun, "/guests"));
    setRegistrationsClosed(params.registrationsClosed);
    setMonthNextEdition(
      params.nextEditionMonth &&
        moment(params.nextEditionMonth).format("MMM. YY")
    );
    setNCVDate(params.ncv && moment(params.ncv).format("YYYY-MM-DD"));
    setCVGDate(params.cvg && moment(params.cvg).format("YYYY-MM-DD"));
  };

  const registrations = {
    ncv: {
      logos: ["ncv.png"],
      price: 20,
      items: ["ncv", "t_shirt", "goodies", "meal"],
      date: ncvDate && moment(ncvDate).format("DD"),
    },
    cvg: {
      logos: ["cvg.png"],
      price: 20,
      items: ["cvg", "t_shirt", "goodies", "meal"],
      date: cvgDate && moment(cvgDate).format("DD"),
    },
    ncv_cvg: {
      logos: ["ncv.png", "cvg.png"],
      price: 35,
      items: ["both", "t_shirt_both", "goodies", "meals"],
      date:
        ncvDate &&
        cvgDate &&
        `${moment(ncvDate).format("DD")}, ${moment(cvgDate).format("DD")}`,
    },
  };

  return {
    registrations,
    shotgunConfig,
    shotgunGuestsConfig,
    monthNextEdition,
    timezone: params.timezone,
    registrationsClosed,
    updateRegistration,
  };
};
