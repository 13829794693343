import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Link, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSignInAlt } from "@fortawesome/free-solid-svg-icons";

import { RegistrationContext } from "../../config/registration/context";

import { Banner } from "../../components/banner";
import { SocialNetworks } from "../../components/socialNetworks";
import { BubbleTopContainer, ColoredSpan } from "../../components/misc";
import { ButtonWrapper } from "../../components/buttonWrapper";

export const EditionCard: React.FC<{
  acronym: string;
}> = ({ acronym, children }) => {
  const { registrations, monthNextEdition } = useContext(RegistrationContext);
  return (
    <Card className="centered">
      <BubbleTopContainer>
        {registrations[acronym].date} {monthNextEdition}
      </BubbleTopContainer>
      <div className="content">
        <div className="centered">
          {registrations[acronym].logos.map((url, i) => (
            <img
              key={i}
              src={`/assets/images/logos/${url}`}
              alt={`${url} logo`}
            />
          ))}
        </div>
        {children}
      </div>
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  width: 200px;
  border-radius: ${({ theme }) => theme.sizes.border_radius};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 25px;
  box-shadow: 0px 2px 15px ${({ theme }) => theme.colors.shadow1};

  @media screen and (min-width: 1024px) {
    align-self: stretch;
    padding-bottom: auto;
  }

  & .content {
    width: 100%;

    & img {
      width: 70px;
    }

    & p {
      margin: 7px 0;
    }

    & span {
      display: block;
      margin-top: 10px;
    }

    & h2,
    h3 {
      text-align: center;
      margin: 10px;
      color: ${({ theme }) => theme.colors.header};
    }
  }
`;

export const Registration: React.FC = () => {
  const { t } = useTranslation();
  const [user, setEmail] = useState<string>("");

  const match = useRouteMatch<{ guests: string }>("/registration/:guests");
  const isGuests = match?.params.guests === "guests";

  const {
    registrations,
    shotgunConfig,
    shotgunGuestsConfig,
    monthNextEdition,
    timezone,
    registrationsClosed,
  } = useContext(RegistrationContext);

  const config = isGuests ? shotgunGuestsConfig : shotgunConfig;

  const currentDate = moment.tz(timezone);
  const isToday =
    config.shotgunMoment && config.shotgunMoment.isSame(currentDate, "day");
  // Définition de la phrase à afficher avant le sg. Si le sg est dans la journée (affichage de l'heure) ou plus tard (affichage de la date)
  const sentenceShotgun =
    config.shotgunMoment &&
    (isToday ? (
      <div>
        {`${t("registration.today_at")} ${config.shotgunMoment.format(
          "HH:mm"
        )} (${t("registration.paris_tz")})`}
      </div>
    ) : (
      <div>
        {`${t("registration.opens_at")} ${config.shotgunMoment.format(
          "MMM Do, HH:mm"
        )} (${t("registration.paris_tz")})`}
      </div>
    ));

  return (
    <div>
      <Banner title={t("navbar.registration")} />
      <div className="margin">
        {config.registrationsOn ? (
          registrationsClosed ? (
            <h3>{t("registration.closed_long")}</h3>
          ) : (
            <>
              <h3>{t("registration.open")}</h3>
              <ColoredSpan color="green">
                {t("registration.free_spectator")}
              </ColoredSpan>
            </>
          )
        ) : config.shotgunMoment ? (
          <h3>{sentenceShotgun}</h3>
        ) : (
          <div>
            <h3>{`${t("registration.title")} ${monthNextEdition}`}</h3>
            <p> {t("registration.subtitle")} </p>
            <SocialNetworks color="black" />
          </div>
        )}

        <RegistrationContainer>
          {Object.keys(registrations).map((acronym) => (
            <EditionCard key={acronym} acronym={acronym}>
              <h2>{registrations[acronym].price}€</h2>
              <div>
                {registrations[acronym].items.map((item, i) => (
                  <p key={i}>
                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                    {t(`registration.items.${item}`)}
                  </p>
                ))}
              </div>
              {config.registrationsOn &&
                (registrationsClosed ? (
                  <h3>{t("registration.closed")}</h3>
                ) : (
                  <div></div>
                  // To enable the button first remove the div and uncomment the following code
                  /*
                  <ButtonWrapper>
                    <a
                      onClick={() => {
                        window.location.replace(
                          "https://ncv.cs-campus.fr/formulaire/nouveau"
                        );
                      }}
                    >
                      {t("navbar.registration")}
                    </a>
                  </ButtonWrapper>
                  */
                ))}
            </EditionCard>
          ))}
        </RegistrationContainer>
        {config.registrationsOn &&
          (registrationsClosed ? (
            <h3></h3>
          ) : (
            <>
              <ButtonWrapper>
                <a
                  onClick={() => {
                    window.location.replace(
                      "https://ncv.cs-campus.fr/formulaire/"
                    );
                  }}
                >
                  {"Les inscriptions c'est par ici !"}
                </a>
              </ButtonWrapper>
            </>
          ))}
        <p>{t("registration.issue")}</p>
      </div>
    </div>
  );
};

const LogInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  & input {
    border-radius: ${({ theme }) => theme.sizes.border_radius} 0 0
      ${({ theme }) => theme.sizes.border_radius};
    height: 38px;
  }

  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & svg {
    display: block;
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0 ${({ theme }) => theme.sizes.border_radius}
      ${({ theme }) => theme.sizes.border_radius} 0;
    background-color: ${({ theme }) => theme.colors.header};
    padding: 11px 15px 11px 11px;
  }
`;

export const RegistrationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;

  & > * + * {
    margin-left: 20px;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;

    & > * + * {
      margin-top: 40px;
      margin-left: 0px;
    }
  }
`;
